// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-apprenticeship-apprentice-jsx": () => import("./../../../src/pages/apprenticeship/apprentice.jsx" /* webpackChunkName: "component---src-pages-apprenticeship-apprentice-jsx" */),
  "component---src-pages-apprenticeship-jsx": () => import("./../../../src/pages/apprenticeship.jsx" /* webpackChunkName: "component---src-pages-apprenticeship-jsx" */),
  "component---src-pages-apprenticeship-mentor-jsx": () => import("./../../../src/pages/apprenticeship/mentor.jsx" /* webpackChunkName: "component---src-pages-apprenticeship-mentor-jsx" */),
  "component---src-pages-apprenticeship-social-enterprise-jsx": () => import("./../../../src/pages/apprenticeship/social-enterprise.jsx" /* webpackChunkName: "component---src-pages-apprenticeship-social-enterprise-jsx" */),
  "component---src-pages-apprenticeship-startups-jsx": () => import("./../../../src/pages/apprenticeship/startups.jsx" /* webpackChunkName: "component---src-pages-apprenticeship-startups-jsx" */),
  "component---src-pages-code-jsx": () => import("./../../../src/pages/code.jsx" /* webpackChunkName: "component---src-pages-code-jsx" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-comparison-js": () => import("./../../../src/pages/comparison.js" /* webpackChunkName: "component---src-pages-comparison-js" */),
  "component---src-pages-custom-code-js": () => import("./../../../src/pages/custom-code.js" /* webpackChunkName: "component---src-pages-custom-code-js" */),
  "component---src-pages-devjoin-js": () => import("./../../../src/pages/devjoin.js" /* webpackChunkName: "component---src-pages-devjoin-js" */),
  "component---src-pages-freelance-development-js": () => import("./../../../src/pages/freelance-development.js" /* webpackChunkName: "component---src-pages-freelance-development-js" */),
  "component---src-pages-guild-jsx": () => import("./../../../src/pages/guild.jsx" /* webpackChunkName: "component---src-pages-guild-jsx" */),
  "component---src-pages-health-tech-jsx": () => import("./../../../src/pages/health-tech.jsx" /* webpackChunkName: "component---src-pages-health-tech-jsx" */),
  "component---src-pages-hire-developers-js": () => import("./../../../src/pages/hire-developers.js" /* webpackChunkName: "component---src-pages-hire-developers-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-javascript-js": () => import("./../../../src/pages/javascript.js" /* webpackChunkName: "component---src-pages-javascript-js" */),
  "component---src-pages-nodejs-js": () => import("./../../../src/pages/nodejs.js" /* webpackChunkName: "component---src-pages-nodejs-js" */),
  "component---src-pages-offerings-diligence-jsx": () => import("./../../../src/pages/offerings/diligence.jsx" /* webpackChunkName: "component---src-pages-offerings-diligence-jsx" */),
  "component---src-pages-offerings-product-development-jsx": () => import("./../../../src/pages/offerings/product-development.jsx" /* webpackChunkName: "component---src-pages-offerings-product-development-jsx" */),
  "component---src-pages-offerings-security-jsx": () => import("./../../../src/pages/offerings/security.jsx" /* webpackChunkName: "component---src-pages-offerings-security-jsx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-react-js": () => import("./../../../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */),
  "component---src-pages-remote-development-js": () => import("./../../../src/pages/remote-development.js" /* webpackChunkName: "component---src-pages-remote-development-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-tasks-js": () => import("./../../../src/pages/tasks.js" /* webpackChunkName: "component---src-pages-tasks-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

